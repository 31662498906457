import styled from '@emotion/styled'
import slugify from '@sindresorhus/slugify'
import { PageProps, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'
import React, { FC } from 'react'

import Breadcrumbs from '../../components/Breadcrumbs'
import Container from '../../components/Container'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO/SEO'
import { SubTitleStyled, TitleStyled } from '../../components/common/Title'
import { useQueryParam } from '../../helpers'
import { useIntlLabel } from '../../intl'
import ItemPopularService from '../home/HomeServicePopularSection/ItemPopularService'
import ItemServiceUnpopular from '../home/HomeServiceUnpopularSection/ItemServiceUnpopular'
import { ServiceIndex } from './__generated__/ServiceIndex'

interface ServiceIndexProps extends PageProps {
    data: ServiceIndex
}

const ServiceListWrapper = styled.div((props) => ({
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: props.theme.spacing(-3),
    marginRight: props.theme.spacing(-3),
    [props.theme.breakpoints.down(props.theme.breakpoints.sm)]: {
        flexDirection: 'column',
        alignItems: 'center',
    },

    a: {
        marginBottom: props.theme.spacing(2),
        background: props.theme.color.neutral.salt[100],
    },
}))

const ServicePage: FC<ServiceIndexProps> = ({ data }) => {
    const getIntlLabel = useIntlLabel()
    const q = useQueryParam('type')
    const intl = useIntl()

    const getCategorySlug = (categoryName: string) => {
        return data.allStrapiServiceCategory.nodes.find(
            (category) => category.categoryName == categoryName,
        )
    }

    return (
        <Layout>
            <SEO
                title={getIntlLabel(data.strapiServiceSection, 'sectionTitle')}
                description={getIntlLabel(data.strapiServiceSection, 'sectionDescription')}
                pageUrl="/services"
            />
            <Breadcrumbs current={'Services'} />
            <Container>
                {q == 'category' ? (
                    <>
                        <TitleStyled>
                            {getIntlLabel(data.strapiHome, 'titlePopularServices')}
                        </TitleStyled>
                        <SubTitleStyled>
                            {intl.formatMessage({ id: 'home_popular_service_subtitle' })}
                        </SubTitleStyled>
                    </>
                ) : (
                    <>
                        <TitleStyled>
                            {getIntlLabel(data.strapiServiceSection!!, 'sectionTitle')}
                        </TitleStyled>
                        <SubTitleStyled>
                            {intl.formatMessage({ id: 'label_all_services' })}
                        </SubTitleStyled>
                    </>
                )}

                <ServiceListWrapper>
                    {(q == 'serviceAll' || q == null) &&
                        data.allStrapiService.nodes.map((service) => {
                            const categorySlug = service.serviceCategories![0]?.categorySlug!!

                            return (
                                <ItemPopularService
                                    //isOnline={service.application !== null}
                                    key={service.id}
                                    to={`/services/${service?.serviceSlug}?category=${slugify(
                                        service?.serviceCategories![0]?.categoryName!!,
                                    )}`}
                                    title={getIntlLabel(service, 'serviceTitle')}
                                    //description={getIntlLabel(service, 'serviceSubTitle')}
                                    category={
                                        getIntlLabel(
                                            service?.serviceCategories?.[0],
                                            'categoryName',
                                        ) ?? ''
                                    }
                                />
                            )
                        })}

                    {q == 'serviceApplication' &&
                        data.allStrapiService.nodes
                            .filter((service) => service.application !== null)
                            .map((service) => {
                                return (
                                    <ItemServiceUnpopular
                                        isOnline
                                        key={service.id}
                                        to={`/services/${service?.serviceSlug}?category=${slugify(
                                            service?.serviceCategories![0]?.categoryName!!,
                                        )}`}
                                        title={getIntlLabel(service, 'serviceTitle')}
                                        description={getIntlLabel(service, 'serviceSubTitle')}
                                    />
                                )
                            })}

                    {q == 'category' &&
                        data.allStrapiServiceCategory.nodes.map((category) => {
                            return (
                                <ItemServiceUnpopular
                                    key={category.id}
                                    icon={category.icon?.url ?? ''}
                                    to={`/services/${category.categorySlug}`}
                                    title={getIntlLabel(category, 'categoryName')}
                                    description={getIntlLabel(category, 'categoryDescription')}
                                />
                            )
                        })}
                </ServiceListWrapper>
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query ServiceIndex {
        allStrapiService {
            nodes {
                serviceName
                serviceTitle
                serviceTitle_mg
                serviceSubTitle
                serviceSubTitle_mg
                serviceName_mg
                application {
                    applicationServeiceName
                    serviceApplicationUrl
                    id
                }
                id
                serviceSlug
                serviceSlug_mg
                serviceCategories {
                    categoryName
                    categoryName_mg
                    categorySlug
                    categorySlug_mg
                }
                servicePersonas {
                    personaSlug
                    personaSlug_mg
                }
            }
        }

        strapiServiceSection {
            sectionTitle
            sectionTitle_mg
        }

        allStrapiServiceCategory {
            nodes {
                categoryName
                categoryName_mg
                categoryDescription
                categoryDescription_mg
                categorySlug
                categorySlug_mg
                id
                icon {
                    url
                }
            }
        }

        strapiServiceSection {
            sectionTitle
            sectionTitle_mg
            sectionDescription
            sectionDescription_mg
        }

        strapiHome {
            titleUnpopularServices
            titleUnpopularServices_mg
            titleVeryRequestedServices
            titleVeryRequestedServices_mg
            titlePopularServices
            titlePopularServices_mg
        }
    }
`

export default ServicePage
