import { Theme, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'gatsby-plugin-react-intl'
import { FC } from 'react'
import { LinkProps } from 'react-router-dom'

interface ItemPopularServiceStyledProps {
    isall: boolean
}

const ItemPopularServiceStyled = styled(Link)<LinkProps & ItemPopularServiceStyledProps>(
    (props) => ({
        borderRadius: props.theme.spacing(3),
        backgroundColor: props.isall
            ? props.theme.color.brand.secondary[800]
            : props.theme.color.semantic.info[200],
        position: 'relative',
        cursor: 'pointer',
        textDecoration: 'none',
        color: props.isall ? '#FFFFFF' : '#0D374D',
        fontSize: props.theme.spacing(2),
        padding: props.theme.spacing(3),
        margin: `0 ${props.theme.spacing(1)}`,
        transition: 'all ease 300ms',
        width: `calc(20% - ${props.theme.spacing(2)})`,
        boxSizing: 'border-box',
        display: props.isall ? 'flex' : '',
        alignItems: props.isall ? 'center' : '',
        justifyContent: props.isall ? 'center' : '',
        marginBottom: props.theme.spacing(3),

        [props.theme.breakpoints.down(props.theme.breakpoints.lg)]: {
            width: `calc(50% - ${props.theme.spacing(2)})`,
        },

        [props.theme.breakpoints.down(props.theme.breakpoints.md)]: {
            width: '100%',
        },

        '&:hover': {
            backgroundColor: props.isall
                ? props.theme.color.brand.secondary[700]
                : props.theme.color.semantic.info[300],
            transition: 'all ease 300ms',
        },

        a: {
            marginBottom: props.theme.spacing(1),
        },
        span: {
            minHeight: props.isall ? 'initial' : '',
            fontSize: props.isall ? props.theme.spacing(2.2) : '',
            fontWeight: props.isall ? 600 : 0,
        },

        [props.theme.breakpoints.down(props.theme.breakpoints.sm)]: {
            marginBottom: props.theme.spacing(2),
        },
    }),
)

const categoryStyle = (theme: Theme) =>
    css({
        display: 'block',
        lineHeight: 1.2,
        minHeight: 80,
    })

const CategoryStyled = styled.div((props) => ({
    padding: `${props.theme.spacing(0.5)} ${props.theme.spacing(1)}`,
    background: props.theme.color.brand.secondary[200],
    borderRadius: props.theme.spacing(10),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    maxWidth: 180,

    span: {
        fontSize: props.theme.spacing(1.8),
    },
}))

interface ItemPopularServiceProps {
    to: string
    title: string
    category?: string
    isall?: boolean
}

const ItemPopularService: FC<ItemPopularServiceProps> = ({
    to,
    title,
    category = '',
    isall = false,
}) => {
    const categoryStyled = isall ? (
        ''
    ) : (
        <CategoryStyled>
            <span>{category ?? ''}</span>
        </CategoryStyled>
    )

    return (
        <ItemPopularServiceStyled isall={isall} to={to}>
            <span css={categoryStyle}>{title}</span>
            {categoryStyled}
        </ItemPopularServiceStyled>
    )
}

export default ItemPopularService
