import styled from '@emotion/styled'

export const TitleStyled = styled.h2({
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 4,
})

export const SubTitleStyled = styled.p(({ theme }) => ({
    padding: 0,
    marginBottom: theme.spacing(4),
    color: theme.color.neutral.slate[300],
    fontWeight: 400,
    fontSize: theme.spacing(2),
}))
